/**
 * @todo consolidate helpers to ./common/helpers
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { flatten, get, isNil, isObject, noop } from 'lodash';
import { RESOURCE_NAME as defaultResourceNames, createUploadRequest, deleteFiles, retrieveAttachments, uploadFiles, TAG_TYPE, MEDIA_TYPE, executeRequest } from '@amzn/austin-core';
import { ACTION_SOURCE, RISK_CRITICALITY, mapRiskChoices } from '@amzn/austin-module-actions';
import { getNewEmptyIssue } from '../actions/legacy-actions/modules/actions';
import { getDueDateInformation, isExtensionRequired } from '../actions/legacy-actions/modules/due-dates';
import { INCIDENT_STATUS, PHASE_TYPE } from './constants';
import { FIELD_TYPES } from '../components/fields/fieldTypes';
import { ERROR_TYPES } from '../components/fields/errorTypes';
import { getAttachments as getV2Attachments } from '../@austin-core-ui/file-attachments/modules/file-service';
import { searchFileIdsByAssociatedIdQuery } from '../gql/file-attachments';
var ATTACHMENT = FIELD_TYPES.ATTACHMENT, DATE_RANGE = FIELD_TYPES.DATE_RANGE, ADD_QUESTIONS = FIELD_TYPES.ADD_QUESTIONS;
var UNDETERMINED = RISK_CRITICALITY.UNDETERMINED;
var PENDING = 'PENDING';
export var CRITICALITY = __assign(__assign({}, RISK_CRITICALITY), { PENDING: PENDING });
// Typescript version in src/common/type.ts 'IncidentsResourceNames'
export var RESOURCE_NAME = __assign({ ACTIONS: 'Actions', FOLLOWUP: 'Followup', FROI: 'FROI', ICARE: 'Icare', INITIAL_ENCOUNTER: 'InitialEncounter', MEDICAL_ATTACHMENTS: 'MedicalAttachments', RCA: 'RCA', REGULATORY: 'Regulatory' }, defaultResourceNames);
/**
 * Reducer function to combine form.sections.questions with form.questions into flat list
 * @todo this can be removed if Form service does not support Form sections
 *
 * @param {Array} sections
 * Form section containing form questions array
 *
 * @param {Array} questions
 * Form questions to be added to reduced question list
 *
 * @returns {Array} questions
 * Flattened form questions
 *
 */
export function combineFormSectionQuestions(_a) {
    var _b = _a.sections, sections = _b === void 0 ? [] : _b, _c = _a.questions, questions = _c === void 0 ? [] : _c;
    return sections
        .reduce(function (allQuestions, _a) {
        var _b = _a.questions, questionSet = _b === void 0 ? [] : _b;
        return allQuestions.concat(questionSet);
    }, [])
        .concat(questions);
}
export function formatAnswerValue(value) {
    return isObject(value) ? JSON.stringify(value) : value;
}
/**
 * Traverses questions and their children (follow ups) based on the set answer value
 * and finds the questions that should not have answers set.  The Form will not allow submission with answers set for these questions.
 *
 * @param {Array} questions
 * Form questions with condition relationships
 * ```javascript
 * {
 *   id: String,
 *   followUpQuestions: [
 *     answerId: String,
 *     followUpQuestionIds: [String]
 *   ]
 * }```
 *
 * @param {Object} answers
 * Answer object with saved values for each question id
 *
 * @returns {Array} invalidQuestionIds
 * List of question ids that do not apply
 */
export function getInvalidQuestions(_a) {
    var _b = _a.questions, questions = _b === void 0 ? [] : _b, _c = _a.answers, answers = _c === void 0 ? {} : _c;
    // set questions as a map for lookup
    var questionMap = questions.reduce(function (map, question) {
        var _a;
        return (__assign(__assign({}, map), (_a = {}, _a[question.id] = question, _a)));
    }, {});
    var validQuestionIds = new Set();
    // start with all the top level questions
    var queue = questions
        .filter(function (_a) {
        var _b = _a.conditions, conditions = _b === void 0 ? [] : _b;
        return !conditions.length;
    })
        .map(function (_a) {
        var id = _a.id;
        return id;
    });
    var _loop_1 = function () {
        var currentId = queue.shift();
        if (currentId) {
            var currentQuestion = questionMap[currentId];
            // Check if the question exists and hasn't been visited
            if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.id) &&
                !validQuestionIds.has(currentQuestion.id)) {
                // if its in the queue and exists then it is applicable
                validQuestionIds.add(currentQuestion.id);
                var questionAnswer_1 = get(answers, currentQuestion.id);
                // Check the question for valid follow up questions based on the answer
                if (questionAnswer_1) {
                    var followUpQuestions = get(currentQuestion, 'followUpQuestions', [])
                        .filter(function (_a) {
                        var answerId = _a.answerId;
                        return hasRequiredCondition(questionAnswer_1, answerId);
                    })
                        .reduce(function (questionIds, _a) {
                        var followUpQuestionIds = _a.followUpQuestionIds;
                        return questionIds.concat(followUpQuestionIds);
                    }, []);
                    // Follow up question ids defined for the current answer are valid
                    queue.push.apply(queue, followUpQuestions);
                }
            }
        }
    };
    while (queue.length) {
        _loop_1();
    }
    // Questions that have an answer saved but are not applicable are invalid
    var invalidQuestions = questions.filter(function (_a) {
        var id = _a.id;
        return get(answers, id) && !validQuestionIds.has(id);
    });
    return invalidQuestions;
}
/**
 * Maps the form questions with answers in formContext
 */
export function mapQuestionAnswers(_a) {
    var _b = _a.questions, questions = _b === void 0 ? [] : _b, _c = _a.answers, answers = _c === void 0 ? {} : _c, _d = _a.fileMap, fileMap = _d === void 0 ? {} : _d;
    return questions
        .filter(function (question) { return answers[question.id] !== undefined; })
        .map(function (_a) {
        var questionId = _a.id, version = _a.version, answerTemplate = _a.answerTemplate;
        var answer = {
            questionId: questionId,
            answerList: null
        };
        if (Array.isArray(answers[questionId])) {
            if ((answerTemplate === null || answerTemplate === void 0 ? void 0 : answerTemplate.renderAs) === ATTACHMENT) {
                var filesForQuestion_1 = get(fileMap, questionId, []);
                answer.answerList = get(answers, questionId, []).map(function (_a) {
                    var id = _a.id, linkingId = _a.linkingId, metadata = _a.metadata, type = _a.type;
                    var fileId = id;
                    if (!fileId) {
                        var fileMetadata = filesForQuestion_1.find(function (file) { return file.linkingId === linkingId; });
                        fileId = get(fileMetadata, 'id');
                    }
                    return formatAnswerValue({
                        id: fileId,
                        linkingId: linkingId,
                        metadata: metadata,
                        type: type
                    });
                });
            }
            else if ((answerTemplate === null || answerTemplate === void 0 ? void 0 : answerTemplate.renderAs) === DATE_RANGE) {
                // date values must be non empty, its possible the last entry is empty, and we allow this
                // but it needs to be pulled off the answerList
                var answerValue_1 = answers[questionId];
                answer.answerList = answerValue_1.filter(function (value, index) {
                    return Boolean(value) ||
                        (index !== answerValue_1.length - 1 &&
                            index !== answerValue_1.length - 2);
                });
            }
            else {
                answer.answerList = answers[questionId].map(formatAnswerValue);
            }
        }
        else if ((answerTemplate === null || answerTemplate === void 0 ? void 0 : answerTemplate.renderAs) === ADD_QUESTIONS) {
            answer.answerList = answers[questionId]
                ? // Multi-entity answerList will be an array of json blob Map<string, string[]>
                    // e.g. "answerList": [ "{\"2abae050-4308-4a3b-ad3a-4a1544f8300f\":[\"car\"],\"e4963661-898e-4a76-8097-aea518694bfe\":[\"mack\"]}"]
                    Object.values(answers[questionId]).map(function (answer) {
                        return JSON.stringify(answer);
                    })
                : answers[questionId];
        }
        return {
            id: questionId,
            answer: answer,
            version: version
        };
    });
}
// TODO: migrate to use this function when incident API is in prod
/**
 * Populate the 'answers' field for saveFormAnswers API request
 * @return {Object} IncidentQuestionAnswer: { questionId: string, answerList: string[] }
 */
export function mapIncidentQuestionAnswers(_a) {
    var _b = _a.questions, questions = _b === void 0 ? [] : _b, _c = _a.answers, answers = _c === void 0 ? {} : _c;
    return questions
        .filter(function (question) { return answers[question.id] !== undefined; })
        .map(function (_a) {
        var questionId = _a.id, answerTemplate = _a.answerTemplate;
        var answer = {
            questionId: questionId,
            answerList: null
        };
        if (Array.isArray(answers[questionId])) {
            if ((answerTemplate === null || answerTemplate === void 0 ? void 0 : answerTemplate.renderAs) === ATTACHMENT) {
                answer.answerList = get(answers, questionId, []).map(function (_a) {
                    var id = _a.id, linkingId = _a.linkingId, metadata = _a.metadata, type = _a.type;
                    return formatAnswerValue({ id: id, linkingId: linkingId, metadata: metadata, type: type });
                });
            }
            else if ((answerTemplate === null || answerTemplate === void 0 ? void 0 : answerTemplate.renderAs) === DATE_RANGE) {
                // date values must be non empty, its possible the last entry is empty, and we allow this
                // but it needs to be pulled off the answerList
                var answerValue_2 = answers[questionId];
                answer.answerList = answerValue_2.filter(function (value, index) {
                    return Boolean(value) ||
                        (index !== answerValue_2.length - 1 &&
                            index !== answerValue_2.length - 2);
                });
            }
            else {
                answer.answerList = answers[questionId].map(formatAnswerValue);
            }
        }
        return answer;
    });
}
/**
 * Checks to see if question field is empty
 */
export function isEmptyField(answer) {
    if (Array.isArray(answer)) {
        return answer.every(checkEmpty);
    }
    return checkEmpty(answer);
}
function checkEmpty(answer) {
    var value = answer !== null && answer !== void 0 ? answer : '';
    return typeof value === 'string' && value.trim() === '';
}
export function hasRequiredCondition(parentQuestionAnswer, conditionValue) {
    if (!parentQuestionAnswer) {
        return false;
    }
    if (Array.isArray(conditionValue)) {
        return conditionValue.some(function (value) {
            return hasRequiredCondition(parentQuestionAnswer, value);
        });
    }
    // Parent question answers can be booleans so they need to be converted to a string to be properly matched against
    // backend condition values which are always strings even if representing a boolean (ex: true boolean is returned as "true" from the backend)
    var formatAnswer = function (answer) { return String(answer); };
    if (Array.isArray(parentQuestionAnswer)) {
        return parentQuestionAnswer.map(formatAnswer).includes(conditionValue);
    }
    return formatAnswer(parentQuestionAnswer) === conditionValue;
}
/**
 * Checks if the file object has FileMetadata from FileService.  This suggests it is an already uploaded file
 * @param {Object} attachment
 * @param {String} attachment.id
 * @param {String} [attachment.suppliedName]
 * @param {String} attachment.type
 * @param {Array} attachment.associatedId
 * @param {Number} attachment.version
 *
 * @returns {Boolean}
 */
export function isUploadedAttachment(attachment) {
    return (attachment.id &&
        (attachment.suppliedName || attachment.type === MEDIA_TYPE.IMAGE) &&
        attachment.associatedIds &&
        attachment.version &&
        !isV2File(attachment));
}
/**
 * Checks file attachment metadata (from form answer value) to determine if this was added via File service v2
 * Backend will add RTW added files data to form answers with fmsVersion
 */
export var FMS_VERSION2 = 'V2';
export function isV2File(attachment) {
    var _a;
    return ((_a = attachment === null || attachment === void 0 ? void 0 : attachment.metadata) === null || _a === void 0 ? void 0 : _a.fmsVersion) === FMS_VERSION2;
}
export function mapFormAnswersToFileMetadata(formAnswers, fileMap) {
    if (formAnswers === void 0) { formAnswers = []; }
    if (fileMap === void 0) { fileMap = {}; }
    return formAnswers.reduce(function (answers, answer) {
        var _a;
        var _b;
        var answerId = answer.answerId, answerList = answer.answerList;
        var fileAttachments = fileMap[answer.questionId];
        if (Array.isArray(fileAttachments) && Array.isArray(answerList)) {
            try {
                var fileIds = Array.from(new Set(__spreadArray(__spreadArray([], fileAttachments, true), answerList, true).filter(function (file) { return file && file.id; })
                    .map(function (_a) {
                    var id = _a.id;
                    return id;
                })));
                var combinedFiles = fileIds.map(function (fileId) {
                    // FileMetadata from FileService for the current form + question
                    var savedFileData = fileAttachments.find(function (file) { return (file === null || file === void 0 ? void 0 : file.id) === fileId; });
                    // File metadata saved on the form answer
                    var formFileData = answerList.find(function (file) { return (file === null || file === void 0 ? void 0 : file.id) === fileId; });
                    if (savedFileData && !formFileData) {
                        formFileData = answerList.find(function (file) {
                            return (file === null || file === void 0 ? void 0 : file.linkingId) &&
                                get(savedFileData, 'associatedIds', []).includes(file.linkingId);
                        });
                    }
                    return __assign(__assign({ metadata: formFileData, isAssociated: Boolean(savedFileData) }, formFileData), savedFileData);
                });
                fileAttachments = combinedFiles;
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.error("Error mapping form answer to Attachment for question: ".concat(answer.questionId), error);
            }
        }
        return __assign(__assign({}, answers), (_a = {}, _a[answer.questionId] = (_b = fileAttachments !== null && fileAttachments !== void 0 ? fileAttachments : answerId) !== null && _b !== void 0 ? _b : answerList, _a));
    }, {});
}
export function saveAttachments(form, getAnswers, questionFileMap) {
    return __awaiter(this, void 0, void 0, function () {
        var questionFiles, persistedQuestionFiles, filesToUpload, uploadRequests, filesMissingId, filesToDelete, _a, filesByAssociatedId, uploads, newQuestionFileMap;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    questionFiles = form.questions
                        .filter(function (_a) {
                        var answerTemplate = _a.answerTemplate, id = _a.id;
                        return answerTemplate.renderAs === ATTACHMENT && !isNil(getAnswers(id));
                    })
                        .flatMap(function (_a) {
                        var id = _a.id;
                        return getAnswers(id).map(function (file) { return ({ file: file, questionId: id }); });
                    });
                    persistedQuestionFiles = flatten(Object.values(questionFileMap));
                    filesToUpload = questionFiles.filter(function (_a) {
                        var file = _a.file;
                        return Boolean(file.file) &&
                            !isUploadedAttachment(file) &&
                            !persistedQuestionFiles.find(function (_a) {
                                var id = _a.id;
                                return id === file.id;
                            }) &&
                            !file.uploaded;
                    });
                    uploadRequests = filesToUpload.map(function (_a) {
                        var file = _a.file, questionId = _a.questionId;
                        var associatedIds = file.linkingId
                            ? [form.id, questionId, file.linkingId]
                            : [form.id, questionId];
                        return {
                            file: file.file,
                            uploadRequest: createUploadRequest(file.file, associatedIds)
                        };
                    });
                    filesMissingId = questionFiles.filter(function (_a) {
                        var file = _a.file;
                        return file.uploaded && file.isMissingId && file.linkingId;
                    });
                    filesToDelete = persistedQuestionFiles
                        .filter(function (_a) {
                        var id = _a.id;
                        return !questionFiles.find(function (_a) {
                            var file = _a.file;
                            return file.id === id;
                        });
                    })
                        .filter(isUploadedAttachment);
                    return [4 /*yield*/, Promise.all([
                            searchFileIds(filesMissingId.map(function (_a) {
                                var file = _a.file;
                                return file.linkingId;
                            })),
                            uploadFiles(uploadRequests),
                            deleteFiles(filesToDelete, __spreadArray([
                                form.id
                            ], form.questions.map(function (_a) {
                                var id = _a.id;
                                return id;
                            }), true))
                        ])];
                case 1:
                    _a = _b.sent(), filesByAssociatedId = _a[0], uploads = _a[1];
                    newQuestionFileMap = questionFiles.reduce(function (fileMap, _a) {
                        var _b;
                        var file = _a.file, questionId = _a.questionId;
                        var fileId = file.id || null;
                        if (!fileId && file.linkingId) {
                            fileId = get(filesByAssociatedId, "".concat(file.linkingId, ".id"), null);
                        }
                        return __assign(__assign({}, fileMap), (_b = {}, _b[questionId] = (fileMap[questionId] || []).concat(__assign(__assign({}, file), { id: fileId, isMissingId: !fileId })), _b));
                    }, {});
                    return [2 /*return*/, newQuestionFileMap];
            }
        });
    });
}
/**
 * Search for file metadata (id, associatedId) by file associated ids
 * @param {Array} associatedIds
 * Associated id to match files.  This should be a question linkingId, or formId
 * @returns {Object} FileMap
 * Object of { [associatedId]: FileMetadata }
 */
export function searchFileIds(associatedIds) {
    if (associatedIds === void 0) { associatedIds = []; }
    return __awaiter(this, void 0, void 0, function () {
        var filesByAssociatedId, _a, files, images, allFiles, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    filesByAssociatedId = {};
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    if (!(associatedIds === null || associatedIds === void 0 ? void 0 : associatedIds.length)) return [3 /*break*/, 3];
                    return [4 /*yield*/, executeRequest(searchFileIdsByAssociatedIdQuery, {
                            associatedIds: associatedIds
                        })];
                case 2:
                    _a = _b.sent(), files = _a.files, images = _a.images;
                    allFiles = flatten(__spreadArray(__spreadArray([], Object.values(get(files, 'entities', [])), true), Object.values(get(images, 'entities', [])), true));
                    filesByAssociatedId = allFiles.reduce(function (fileMap, file) {
                        var _a;
                        var associatedId = file.associatedIds.find(function (id) {
                            return associatedIds.includes(id);
                        });
                        return __assign(__assign({}, fileMap), (_a = {}, _a[associatedId] = file, _a));
                    }, {});
                    _b.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_1 = _b.sent();
                    // eslint-disable-next-line no-console
                    console.error('Error from searchAllFilesByAssociatedIds', err_1, associatedIds);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/, filesByAssociatedId];
            }
        });
    });
}
/**
 * File uploads need to be searched by associated ids
 * This loads associated files and builds a map of the question id to be used for rendering
 *
 * @param {string} formId - the associated formId to search files
 * @param {Array} [additionalFiles] - Array of additional FileAttachments to be included in fileMap.
 * @param {function} [getFileMapId] - Optional, though recommended, helper method to generate the fileMap id for attached files.  This should be the questionId.
 *
 * @return {Object} fileMap
 * { [questionId: string]: FileAttachments[]}
 */
export function getFiles(formId, additionalFiles, getFileMapId) {
    if (additionalFiles === void 0) { additionalFiles = []; }
    return __awaiter(this, void 0, void 0, function () {
        var _a, files, images, allFiles, newQuestionFileMap;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, retrieveAttachments([formId])];
                case 1:
                    _a = _b.sent(), files = _a.files, images = _a.images;
                    allFiles = flatten(__spreadArray(__spreadArray([], Object.values(files), true), Object.values(images), true));
                    // additional files from v2 need to be fetched separately and combined with the file map
                    // to be associated with question ids
                    if (additionalFiles === null || additionalFiles === void 0 ? void 0 : additionalFiles.length) {
                        allFiles = allFiles.concat(additionalFiles);
                    }
                    newQuestionFileMap = allFiles.reduce(function (fileMap, file) {
                        var _a;
                        var questionId = getFileMapId
                            ? getFileMapId(file, formId)
                            : file.associatedIds.filter(function (id) { return id !== formId; })[0];
                        return __assign(__assign({}, fileMap), (_a = {}, _a[questionId] = (fileMap[questionId] || []).concat(file), _a));
                    }, {});
                    return [2 /*return*/, newQuestionFileMap];
            }
        });
    });
}
/**
 * @temp Temp implementation to fetch files from file service v2.  This should be migrated to FileAttachment component + useFileAttachment hooks
 * Forms does not update file associatedIds currently, which is a blocker for migration.
 *
 * @param {Array} fileIds - List of file ids to fetch attachment data and signed urls
 *
 * @return {Array} FileAttachments
 */
export function getFilesV2(fileIds) {
    return __awaiter(this, void 0, void 0, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    data = [];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getV2Attachments(fileIds)];
                case 2:
                    data = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, data];
            }
        });
    });
}
/**
 * Helper comparison functio to sorting a list of answerOptions by answerId ascending / alphabetical.
 * To be used as parameter to Array.prototype.sort
 *
 * ```javascript
 *   [{ answerId: 'answerB' }, { answerId: 'answerA'}].sort(sortOptionsByAnswerId)
 * ```
 *
 * @param {Object} optionA { answerId: String }
 * @param {Object} optionB { answerId: String }
 * @returns {Number} -1, 1, 0 comparision result to sort
 */
export function sortOptionsByAnswerId(optionA, optionB) {
    var labelA = get(optionA, 'answerId', '').toUpperCase();
    var labelB = get(optionB, 'answerId', '').toUpperCase();
    if (labelA < labelB) {
        return -1;
    }
    if (labelA > labelB) {
        return 1;
    }
    return 0;
}
/**
 * Sorts a list of phases by phase type in place.
 *
 * @param {Array} phases List of phases represented as objects or strings
 * @param {String} path Path to look for the phase type in the phase object
 * @returns
 */
export function sortPhases(phases, path) {
    var defaultIndex = Number.MAX_SAFE_INTEGER;
    phases.sort(function (a, b) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (path) {
            return (((_b = (_a = getPhaseVariables(get(a, path))) === null || _a === void 0 ? void 0 : _a.ordinal) !== null && _b !== void 0 ? _b : defaultIndex) -
                ((_d = (_c = getPhaseVariables(get(b, path))) === null || _c === void 0 ? void 0 : _c.ordinal) !== null && _d !== void 0 ? _d : defaultIndex));
        }
        return (((_f = (_e = getPhaseVariables(a)) === null || _e === void 0 ? void 0 : _e.ordinal) !== null && _f !== void 0 ? _f : defaultIndex) -
            ((_h = (_g = getPhaseVariables(b)) === null || _g === void 0 ? void 0 : _g.ordinal) !== null && _h !== void 0 ? _h : defaultIndex));
    });
    return phases;
}
/**
 * Helper filter function
 * returns true if question is not nested/conditional or if the condition answer is set
 * @param {Object} [question]
 * The question object
 *
 * @param getAnswers
 * @param parentQuestionId
 * @param questionId
 * @param showHiddenQuestions
 * @param suppliedAnswers
 * @return {Boolean}
 */
export function shouldDisplayField(_a) {
    var question = _a.question, getAnswers = _a.getAnswers, _b = _a.multiEntityProps, _c = _b === void 0 ? {
        parentQuestionId: undefined,
        questionId: undefined,
        showHiddenQuestions: false
    } : _b, parentQuestionId = _c.parentQuestionId, questionId = _c.questionId, showHiddenQuestions = _c.showHiddenQuestions, suppliedAnswers = _a.answers;
    var _d = question.conditions, conditions = _d === void 0 ? [] : _d, _e = question.conditionType, conditionType = _e === void 0 ? 'OR' : _e, _f = question.hidden, hidden = _f === void 0 ? false : _f;
    // check question.conditions for nested field requirements
    var isConditionalField = !!(conditions === null || conditions === void 0 ? void 0 : conditions.length);
    var conditionMet = conditionType === 'AND'
        ? conditions.every(function (condition) {
            return hasRequiredCondition(suppliedAnswers !== null && suppliedAnswers !== void 0 ? suppliedAnswers : getAnswers(condition.field), condition.value);
        })
        : // prettier-ignore
            conditions.some(function (condition) {
                var _a, _b;
                if (parentQuestionId && questionId) {
                    return hasRequiredCondition((_b = (_a = (suppliedAnswers !== null && suppliedAnswers !== void 0 ? suppliedAnswers : getAnswers(parentQuestionId))) === null || _a === void 0 ? void 0 : _a[questionId]) === null || _b === void 0 ? void 0 : _b[condition.field], condition.value);
                }
                return hasRequiredCondition(suppliedAnswers !== null && suppliedAnswers !== void 0 ? suppliedAnswers : getAnswers(condition.field), condition.value);
            });
    var isHiddenField = Boolean(get(question, 'answerTemplate.type') === FIELD_TYPES.HIDDEN_FIELD) || hidden;
    return ((isConditionalField && conditionMet) ||
        (!isConditionalField && !isHiddenField) ||
        (isHiddenField && showHiddenQuestions && !isConditionalField));
}
export function fieldRequired(_a) {
    var _b;
    var question = _a.question, getAnswers = _a.getAnswers, multiEntityProps = _a.multiEntityProps;
    // checkboxes/checklist/expansionList are inherently answered as false
    var isCheckbox = Boolean([FIELD_TYPES.CHECKBOX, FIELD_TYPES.EXPANSION_LIST].includes(get(question, 'answerTemplate.renderAs', (_b = question === null || question === void 0 ? void 0 : question.answerTemplate) === null || _b === void 0 ? void 0 : _b.type)));
    // Check questions.attributes for 'optional'
    var isOptional = Boolean(question === null || question === void 0 ? void 0 : question.optional);
    return (shouldDisplayField({
        question: question,
        getAnswers: getAnswers,
        multiEntityProps: multiEntityProps
    }) &&
        !isCheckbox &&
        !isOptional);
}
/**
 * Temp form validation for demo
 */
export function defaultValidateForm(_a) {
    var getAnswers = _a.getAnswers, _b = _a.sections, sections = _b === void 0 ? [] : _b, _c = _a.questions, questions = _c === void 0 ? [] : _c, errors = _a.errors, setErrors = _a.setErrors, _d = _a.onValidate, onValidate = _d === void 0 ? noop : _d;
    var answers = getAnswers();
    // validate current form
    var sectionQuestions = sections
        .reduce(function (allQuestions, _a) {
        var _b = _a.questions, questionSet = _b === void 0 ? [] : _b;
        return allQuestions.concat(questionSet);
    }, [])
        .concat(questions)
        .filter(function (question) { return fieldRequired({ question: question, getAnswers: getAnswers }); })
        .map(function (q) { return q.id; });
    // Some components like `DateField` set errors from the component itself.
    // So iterate over the `errors` object to find them.
    var invalidErrorFields = Object.keys(errors || {}).reduce(function (err, fieldName) {
        var _a;
        var error = errors[fieldName];
        // Require fields are already handled, NOT_APPLICABLE field will be removed on submission
        if (error &&
            ![ERROR_TYPES.REQUIRED, ERROR_TYPES.NOT_APPLICABLE].includes(error.type)) {
            return __assign(__assign({}, err), (_a = {}, _a[error.fieldName] = error, _a));
        }
        return err;
    }, {});
    var questionMap = questions.reduce(function (previous, question) {
        var _a;
        return (__assign(__assign({}, previous), (_a = {}, _a[question.id] = question, _a)));
    }, {});
    var requiredErrorFields = sectionQuestions
        .map(function (fieldName) {
        var _a, _b, _c;
        var question = questionMap[fieldName];
        // If multi-incident
        if (((_b = (_a = question === null || question === void 0 ? void 0 : question.answerTemplate) === null || _a === void 0 ? void 0 : _a.nestedQuestionIds) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            var emptyRequiredFields_1 = new Map();
            (_c = Object.entries(answers[question.id] || [])) === null || _c === void 0 ? void 0 : _c.forEach(function (_a) {
                var _b, _c;
                var key = _a[0], answer = _a[1];
                (_c = (_b = question === null || question === void 0 ? void 0 : question.answerTemplate) === null || _b === void 0 ? void 0 : _b.nestedQuestionIds) === null || _c === void 0 ? void 0 : _c.forEach(function (id) {
                    var _a;
                    var subQuestion = questionMap[id];
                    if (fieldRequired({
                        question: subQuestion,
                        getAnswers: getAnswers,
                        multiEntityProps: {
                            parentQuestionId: question.id,
                            questionId: key,
                            showHiddenQuestions: true
                        }
                    }) &&
                        isEmptyField(answer === null || answer === void 0 ? void 0 : answer[id])) {
                        emptyRequiredFields_1.set(key, __assign(__assign({}, emptyRequiredFields_1.get(key)), (_a = {}, _a[id] = {
                            fieldName: id,
                            type: ERROR_TYPES.REQUIRED,
                            message: 'Field is required.'
                        }, _a)));
                    }
                });
            });
            return emptyRequiredFields_1.size > 0
                ? {
                    fieldName: fieldName,
                    errorFields: emptyRequiredFields_1,
                    type: ERROR_TYPES.REQUIRED,
                    message: 'Field is required.'
                }
                : undefined;
        }
        if (isEmptyField(answers[fieldName])) {
            return {
                fieldName: fieldName,
                type: ERROR_TYPES.REQUIRED,
                message: 'Field is required.'
            };
        }
        return undefined;
    })
        .filter(function (item) { return !!item; });
    var errorFields = __assign(__assign({}, invalidErrorFields), requiredErrorFields.reduce(function (err, error) {
        var _a;
        return (__assign(__assign({}, err), (_a = {}, _a[error.fieldName] = error, _a)));
    }, {}));
    setErrors(errorFields);
    var isValid = Object.values(errorFields).every(function (error) { return !error; });
    if (!isValid) {
        // eslint-disable-next-line no-console
        console.log({ isValid: isValid, errorFields: errorFields });
    }
    onValidate({ isValid: isValid, errorFields: errorFields });
    return isValid;
}
/**
 * Validates that the required fields to create a new action are valid
 * @param {Object} action
 * The general action data
 *
 * @param {String} criticality
 * Risk criticality, generally form the parent finding (HIGH, CRITICAL, etc);  Will be used to determine SLA of dueDate
 *
 * @todo Remove when findings 2.0 integration is complete
 * @deprecated
 *
 * @returns {boolean} valid
 */
export function validateNewAction(action, findingCriticality, errors) {
    if (errors === void 0) { errors = {}; }
    if (action.status === 'CLOSED') {
        return true;
    }
    // Default to Undetermined in case actual due date cannot be found
    var dueDateInfo = getDueDateInformation(action, UNDETERMINED);
    try {
        dueDateInfo = getDueDateInformation(action, findingCriticality);
    }
    catch (error) {
        // log error for undefined risk
        // eslint-disable-next-line no-console
        console.error(error);
    }
    // actions with id are already created and dont need justification submitted
    var justificationRequired = !action.id &&
        isExtensionRequired(dueDateInfo === null || dueDateInfo === void 0 ? void 0 : dueDateInfo.standard, action === null || action === void 0 ? void 0 : action.dueDate);
    return Boolean(action.assignee &&
        action.name &&
        action.dueDate &&
        !(errors === null || errors === void 0 ? void 0 : errors[action.linkingId]) &&
        (!justificationRequired || action.justification));
}
export function getGeneralFinding(_a) {
    var _b;
    var caseNumber = _a.caseNumber, customSearchAttributes = _a.customSearchAttributes, incidentId = _a.incidentId, siteId = _a.siteId, incidentCreator = _a.incidentCreator, riskAssessments = _a.riskAssessments, riskCategory = _a.riskCategory, findingProps = __rest(_a, ["caseNumber", "customSearchAttributes", "incidentId", "siteId", "incidentCreator", "riskAssessments", "riskCategory"]);
    var hazard = [];
    if (riskCategory === null || riskCategory === void 0 ? void 0 : riskCategory.group) {
        hazard.push({ text: riskCategory.group, type: TAG_TYPE.GROUP });
    }
    if (riskCategory === null || riskCategory === void 0 ? void 0 : riskCategory.category) {
        hazard.push({ text: riskCategory.category, type: TAG_TYPE.CATEGORY });
    }
    if (riskCategory === null || riskCategory === void 0 ? void 0 : riskCategory.hazard) {
        hazard.push({ text: riskCategory.hazard, type: TAG_TYPE.HAZARD });
    }
    var findingRiskAssessments = riskAssessments.map(function (r) { return ({
        choices: mapRiskChoices(r.likeliness, r.severity),
        date: new Date(),
        user: incidentCreator
    }); });
    var incidentDescription = (_b = customSearchAttributes.find(function (_a) {
        var key = _a.key;
        return key === 'incidentDescription';
    })) === null || _b === void 0 ? void 0 : _b.value;
    return getNewEmptyIssue(__assign({ name: caseNumber + (incidentDescription ? " ".concat(incidentDescription) : ''), hazard: hazard, isPreCreate: false, owner: incidentCreator, riskAssessments: findingRiskAssessments, siteId: siteId, sources: [
            {
                id: incidentId,
                name: 'Incident General Finding',
                type: ACTION_SOURCE.INCIDENT
            }
        ] }, findingProps));
}
export function getPhaseSections(phases) {
    var sortedPhases = sortPhases(__spreadArray([], phases, true), 'type');
    // Use Map to maintain order of sections
    var phaseSections = new Map();
    sortedPhases.forEach(function (phase) {
        var _a;
        var sectionType = phase.section;
        var phasesInSection = (_a = phaseSections.get(sectionType)) !== null && _a !== void 0 ? _a : [];
        phaseSections.set(sectionType, __spreadArray(__spreadArray([], phasesInSection, true), [phase], false));
    });
    return phaseSections;
}
export function getPhaseVariables(phaseType) {
    var _a;
    var _b;
    var DEFAULT = {
        resourceName: RESOURCE_NAME.INCIDENT,
        requiresAssignee: false,
        ordinal: Number.MAX_SAFE_INTEGER
    };
    var phases = (_a = {},
        _a[PHASE_TYPE.SUMMARY] = {
            resourceName: RESOURCE_NAME.INCIDENT,
            requiresAssignee: false,
            ordinal: 0
        },
        _a[PHASE_TYPE.MANAGE_INCIDENT] = {
            resourceName: RESOURCE_NAME.INCIDENT,
            requiresAssignee: false,
            ordinal: 1
        },
        _a[PHASE_TYPE.REVIEW] = {
            resourceName: RESOURCE_NAME.INCIDENT,
            requiresAssignee: true,
            ordinal: 2
        },
        _a[PHASE_TYPE.RECORD] = {
            resourceName: RESOURCE_NAME.INCIDENT_RECORD,
            requiresAssignee: true,
            ordinal: 3
        },
        _a[PHASE_TYPE.INVESTIGATION] = {
            resourceName: RESOURCE_NAME.INCIDENT_INVESTIGATION,
            requiresAssignee: true,
            ordinal: 4
        },
        _a[PHASE_TYPE.RCA] = {
            resourceName: RESOURCE_NAME.RCA,
            requiresAssignee: true,
            ordinal: 5
        },
        _a[PHASE_TYPE.REGULATORY] = {
            resourceName: RESOURCE_NAME.REGULATORY,
            requiresAssignee: true,
            ordinal: 6
        },
        _a[PHASE_TYPE.GARI] = {
            resourceName: RESOURCE_NAME.REGULATORY,
            requiresAssignee: true,
            ordinal: 7
        },
        _a[PHASE_TYPE.ACTIONS] = {
            resourceName: RESOURCE_NAME.ACTIONS,
            requiresAssignee: false,
            ordinal: 8
        },
        _a[PHASE_TYPE.IRF] = {
            resourceName: RESOURCE_NAME.FROI,
            requiresAssignee: false,
            ordinal: 9
        },
        _a[PHASE_TYPE.CONNECTED_INCIDENTS] = {
            resourceName: RESOURCE_NAME.INCIDENT,
            requiresAssignee: false,
            ordinal: 10
        },
        _a[PHASE_TYPE.INITIAL_ENCOUNTER] = {
            resourceName: RESOURCE_NAME.INITIAL_ENCOUNTER,
            requiresAssignee: true,
            ordinal: 11
        },
        _a[PHASE_TYPE.FOLLOWUP] = {
            resourceName: RESOURCE_NAME.FOLLOWUP,
            requiresAssignee: false,
            ordinal: 12
        },
        _a[PHASE_TYPE.ICARE] = {
            resourceName: RESOURCE_NAME.ICARE,
            requiresAssignee: false,
            ordinal: 13
        },
        _a[PHASE_TYPE.MEDICAL_ATTACHMENTS] = {
            resourceName: RESOURCE_NAME.MEDICAL_ATTACHMENTS,
            requiresAssignee: false,
            ordinal: 14
        },
        _a);
    return (_b = phases[phaseType]) !== null && _b !== void 0 ? _b : DEFAULT;
}
/**
 * Checks the incident for ExternalService and initialLoad flags
 * Incidents that are imported via an ExternalService will have initialLoad set as true, which indicates that this incident should not be edited
 * until the flag is set to false.  The incident by default should be considered 'editable' if these properties do not exist.
 *
 * @param {Object} [incident]
 * The incident object which may have incident.customSearchAttributes.EXTERNAL_SERVICE and incident.customSearchAttributes.initialLoad properties.
 * {
 *   customSearchAttributes: [
 *     { key: 'initialLoad', value: 'true' },
 *     { key: 'EXTERNAL_SERVICE', value: 'GENSUITE' },
 *     { key: 'EXTERNAL_SERVICE_ID', value: '1_AR Sortable_DEMO1_111'}
 *   ]
 * }
 *
 */
export function isEditableIncident(incident) {
    var _a, _b, _c, _d;
    var initialLoadKey = 'initialLoad';
    var externalServiceKey = 'EXTERNAL_SERVICE';
    /**
     * checking for Incident status;
     */
    var isArchivedOrDeletedIncident = [
        INCIDENT_STATUS.ARCHIVED,
        INCIDENT_STATUS.DELETED
    ].includes(incident === null || incident === void 0 ? void 0 : incident.status);
    var isExternalIncident = Boolean((_a = incident === null || incident === void 0 ? void 0 : incident.customSearchAttributes) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var key = _a.key;
        return key === externalServiceKey;
    }));
    var isInitialLoad = ((_d = (_c = (_b = incident === null || incident === void 0 ? void 0 : incident.customSearchAttributes) === null || _b === void 0 ? void 0 : _b.find(function (_a) {
        var key = _a.key;
        return key === initialLoadKey;
    })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '').toLowerCase() === 'true';
    return (!isArchivedOrDeletedIncident &&
        (!isExternalIncident || (isExternalIncident && !isInitialLoad)));
}
